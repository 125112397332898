.imageview-div{
    width: 100%;
    aspect-ratio: 1/1;
}

.imageview-div:hover{
    border: solid 1px gray;
}

.imageview-img{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.imageviewimage{
    max-width: 100%;
    max-height: 100%;
}

.imageview-details{
    width: 100%;
    height: 20%;
    padding: 5px;
}

.delete{
    color: red;
    opacity: 0.7;
    position: absolute;
    top: 2px;
    right: 2px;
    background-color: whitesmoke;
    aspect-ratio: 1/1;
    border-radius: 1px;
}

.delete:hover{
    opacity: 1;
}

.primary{
    color: blue;
    opacity: 0.7;
    position: absolute;
    top: 2px;
    left: 2px;
    background-color: whitesmoke;
    aspect-ratio: 1/1;
    border-radius: 1px;
}

.primary:hover{
    opacity: 1;
}

.nonprimary{
    color: gray;
    opacity: 0.7;
    position: absolute;
    top: 2px;
    left: 2px;
    background-color: whitesmoke;
    aspect-ratio: 1/1;
    border-radius: 1px;
}

.nonprimary:hover{
    opacity: 1;
}