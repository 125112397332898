#breadcrumbsdiv{
    margin-bottom: 30px;
}

#breadcrumbsdiv a{
    width: 219px;
    height: 20px;
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    color: #64748B !important;
    text-decoration: none !important;
}

#breadcrumbsdiv a:hover{
    color: #000 !important;
    text-decoration: none !important;
}