.upload-div{
    width: 100%;
    aspect-ratio: 1/1;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.uploadicon{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.uploadicon:hover{
    opacity: 0.5;
}

.uploadicon span{
    font-size: 12px;
}

.hidden{
    display: none;
}

.uploadimage{
    max-width: 100%;
    max-height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}