.subtext{
    color: #64748B;
}

.subtitle{
    font-style: normal;
    font-weight: 400;
    font-size: 25px;
    line-height: 36px;
    color: #64748B;
    margin: 0;
}