.subtext{
    color: #64748B;
}

.subtitle{
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 36px;
    color: #64748B;
    margin: 0;
}

.product-image{
    width: 100%;
    aspect-ratio: 1/1;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1px;
}

.valign{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.valign h6{
    margin-bottom: 0px;
}

.group{
    border: 1px solid lightgray;
}

.createspec{
    border: 1px solid lightgray;
    opacity: 0.7;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    aspect-ratio: 1/1;
}

.createspec:hover{
    border-color: gray;
    opacity: 1;
}

.spec{
    opacity: 1;
    position: relative;
}

.spec:hover{
    border-color: gray;
}

.spec:hover .spec-detail{
    opacity: 0.1;
}

.spec:hover .spec-ctrl{
    opacity: 1;
}

.spec-ctrl{
    transition: .5s ease;
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    text-align: center;
}

.chip:hover{
    font-weight: bold;
    color: #1976d2;
    cursor: pointer;
}