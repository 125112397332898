.menuitem {
  width: 100%;
  padding-top: .6rem;
  padding-bottom: 0.6rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  margin-bottom: 0.3rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  color: #64748B;
  background-color: #ffffff;
}

.menuitem span {
  color: #64748B;
}

.menuitem:hover {
  background-color: #E2E8F0;
  border-radius: 5px;
}

.menuitem .active {
  background-color: #E2E8F0;
  border-radius: 5px;
}

.menuitem .disable {
  background-color: #E2E8F0;
  border-radius: 5px;
  cursor: not-allowed;
}

.menuchild {
  padding-left: 2.5rem;
}

.active div {
  background-color: #E2E8F0;
  border-radius: 5px;
}

.disable div {
  cursor: not-allowed;
  opacity: 0.2;
}

.link{
    text-decoration: none;
}

.parent {
  background-color: #ececec;
}