.table-custom {
    background-color: white !important;
}

.table-custom thead {
    border-top: 1px solid #dee2e6 !important;
}

.table-custom thead tr td{
    font-size: 11px !important;
    line-height: 15px !important;
    background-color: #183553 !important;
    color: white !important;
    padding-block: 10px !important;
}

.table-custom thead tr td label{
    line-height: 20px;
}

.table-custom thead tr td:first-child {
    padding-left: 10px;
}

.table-custom thead tr td:last-child {
    padding-right: 10px;
}

.table-custom tbody tr{
    border-top: 1px solid #dee2e6 !important;
}

.table-custom tbody tr td{
    font-size: 11px !important;
    line-height: 15px !important;
    color: #183553 !important;
    padding-inline: 3px !important;
    padding-block: 10px !important;
    background-color: white;
}

.table-custom tbody tr td:first-child {
    padding-left: 10px !important;
}

.table-custom tbody tr td:last-child {
    padding-right: 10px !important;
}

.table-custom tbody tr td label {
    font-size: 11px !important;
    line-height: 15px !important;
}

.table-custom tbody tr td button {
    font-size: 11px !important;
    line-height: 15px !important;
}

.table-custom tbody tr td .array {
    display: flex;
    flex-direction: column;
    row-gap: 3px;
}

.table-custom tbody tr td .smaller{
    font-size: 10px !important;
}


.table-custom tfoot {
    border-block: 1px solid #dee2e6 !important;
}

.table-custom tfoot tr td {
    font-size: 11px !important;
    line-height: 15px !important;
    color: #183553 !important;
    background-color: white;
    padding-block: 8px !important;
}

.table-custom tfoot tr td div label{
    line-height: 25px;
}

.table-custom tfoot tr td button:disabled{
    opacity: 1 !important;
    color: lightgray !important;
}

.table-custom tfoot tr td:first-child {
    padding-left: 10px;
}

.table-custom tfoot tr td:last-child {
    padding-right: 10px;
}

.table-custom tfoot tr td select{
    border: none;
    text-align: end;
    font-size: 12px !important;
    color: #183553 !important;
}

.menuitem span{
    font-size: 12px;
}