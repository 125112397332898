.main {
    display: flex;
    flex-direction: row;
}

.menu-div{
    width: 280px;
    padding-inline: 5px;
    margin-top: 60px;
    position: fixed;
    background-color: #FAFAFA;
    height: 100%;
}

.outlet-div{
    margin-left: 280px;
    margin-top: 60px;
    width: calc(100% - 280px);
    padding-left: 10px;
    padding-right: 20px;
}



.content {
    height: 100vh;
    padding-top: 4rem;
    background-color: #F1F5F9;
}

.menu {
    padding-top: 80px;
    width: 300px;
    background-color: #FAFAFA;
}

.main {
    width: 100%;
}
.portal
{
    padding: 10px;

    color: white;
    font-weight: bold;
    margin-top: 12px;
    margin-bottom: 4px;
}
.portal.p-admin
{
    background: black;
}

.portal.p-supplier
{
    background: #a30046;
}
.mt-6
{
    margin-top: 66px;
}

.fixed-nav
{
    position: fixed;
    width: calc(16.66666667% - 24px);
}

.item:hover
{
    background-color: #E2E8F0 !Important;  
}
.fixed-nav > div > div > div.item,
.fixed-nav > div > a > div.item
{
background: #ececec;

}
.fixed-nav > div > a > div.item > span
{
    text-transform: uppercase  !important;
}
.fixed-nav > div > a > div.item.child
{
    background:#fff
}
.fixed-nav > div > a > div.item.child > span
{
    text-transform: capitalize  !important;
}
.my-nav
{
    background: #f9f9f9;
}
.vh-100
{
    height: 100vh;
}
.nav-bar
{
    border-bottom: 1px solid #ccc !important;
}
@media only screen and (max-width: 576px) {
    .fixed-nav
    {
        position: relative;
        width: 100%;
    }
  }