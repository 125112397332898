.div-header {
    border-bottom: 1px solid #f3850e;
    background-color: #f3850e;
    color: white;
}

.notif-item{
    width: 100%;
    padding: 10px;
    border-radius: 10px;
    cursor: pointer;
    color: #64748B;
    border: 1px solid whitesmoke;
    margin-block: 5px;
    font-size: 12px;
}

.notif-item:hover{
    border: 1px solid #f3850e;
}

.notif-item-read{
    width: 100%;
    padding: 10px;
    border-radius: 10px;
    cursor: pointer;
    color: #64748B;
    border: 1px solid whitesmoke;
    background: whitesmoke;
    margin-block: 5px;
    font-size: 12px;
}

.notif-item-read:hover{
    border: 1px solid #f3850e;
}