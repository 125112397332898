.content {
    display: flex;
    justify-content: center;
    padding-top: 0rem;
}

#bg-img {
    width: 100%;
    /* mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0)); */
}

.form {
    position: absolute;
    height: min-content;
    align-items: center;
    border-radius: 0.5rem;
    width: 30%;
    padding: 3rem;
    margin-top: 5rem;
    top: 5%;
    background-color: #e8ecf2;
}

.title {
    font-family: 'Unbounded', cursive, sans-serif;
}

#logo {
    height: 100px
}

.my-title {
    /* display: none; */
    font-weight: bold;
    margin: 0;
    font-size: 20px;
    color: #444444;
    padding-top: 20px;
}
.button-login{
background:#00305d !Important
}
.forgot{
    cursor: pointer;
}

.forgot:hover{
    color: #00305d;
}