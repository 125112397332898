.title {
    color: #000000 !important;
    padding-left: 1.5rem;
    padding-right: 3rem;
    cursor: pointer;
}

#basic-nav-dropdown {
    padding-top: 0px;
    padding-bottom: 0px;
    margin-top: 0px;
    margin-bottom: 0px;
}

.rounded-pill {
    position: absolute;
    right: 20%;
    top: -30% !important;
}

#my-logo {
    height: 50px;
}